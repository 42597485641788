/**
  * Name: Monteno | NFT Marketplace ReactJS Template
  * Version: 1.0.1
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/

@use './scss/' as *;
@use './components/layouts/' as *;

@font-face {
  font-family:"Gilroy-Bold";
  src: url("assets/font/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family:"Roboto";
  src: url("assets/font/Roboto-Regular.ttf") format("truetype");
}

a {
  cursor: pointer!important;
}
.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}
.video-container iframe {
  /* Make video to at least 100% wide and tall */
  min-width: 80%; 
  min-height: 80%; 
  z-index: 1003;  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.claimbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @include tablet {
    flex-direction: column;
    align-items: center;
  }
}

.claimbtn > a {
  margin: 0 12.5px;
  width: 300px;
  text-align: center;
  @include tablet {
    margin: 12.5px 0;
    
  }
}

.loginform {
  @include tablet {
    padding: 0 30px!important;
    
  }
}

.claimbtn > button{
  border: none;
  width: 300px;
  border-radius: 33px;
  padding: 20px 48px;
}

.installMetamask{
  background-color: #00000064;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top:0;
  left:0;
  right:0;
  bottom: 0;
}

#leftbtn{
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: -60px;
  z-index: 1;
  line-height: 60px;
  display: block;
  width: 60px;
  // background-color: #7153d9BC;
  background-color: #00000032;
  backdrop-filter: blur(10px);
  border: 1px solid #7153d9BC!important;
  color: white;
  // background: none;
  border-radius: 50%;
  transition: .3s;
  @include tablet {
    left: 0;
  }
  
}

#rightbtn{
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  right: -60px;
  z-index: 1;
  line-height: 60px;
  display: block;
  width: 60px;
  // background-color: #7153d9BC;
  background-color: #00000032;
  backdrop-filter: blur(10px);
  border: 1px solid #7153d9BC!important;
  color: white;
  // background: none;
  border-radius: 50%;
  transition: .3s;
  @include tablet {
    right: 0;
  }
}

#rightbtn:hover, #leftbtn:hover {
  background-color: #7153d9BC;
}

.installMetamask > div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.claiming{
  opacity: 0.75 !important;
}

.addContent{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #00000064;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  z-index: 3;
}

.contentToAdd {
  position: relative;
  z-index: 3;
  width:700px;
  padding: 50px; 
  height:700px; 
  background:rgba(46, 37, 45, 0.7);
  border: 1px solid purple;
  border-radius: 10px;
}


.container{
  position: relative;
}

body {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  color: $white;
  padding-top: $header-height;
  padding-bottom: $header-height;
  overflow-x: hidden;
  background: 'none';
  // background-image: url('../src/assets/images/background/bg-element.jpg');

  &.home-1 {
      background-image: url('../src/assets/images/background/bg-element.jpg');
  }
  &.home-2 {
      background-image: url('../src/assets/images/background/bg-element-2.jpg');
  }
}

.row>* {
  padding-left: 15px;
  padding-right: 15px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Gilroy-Bold';
  font-weight: bold;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: 60px;
}
h3,
.h3 {
  font-size: 48px;
}
h5,
.h5 {
  font-size: 24px;
}
h6,
.h6 {
  font-size: 21px;
}

.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
  line-height: 1.7;
}
.fs-21 {
  font-size: 21px;
}
.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 30px;
}
.fs-36 {
  font-size: 36px;
}
.fs-42 {
  font-size: 42px;
}
.mr-0 {
  margin-right: 0 !important;
}
.pt-128 {
  padding-top: 128px;
}
.mt--16 {
  margin-top: -16px;
}
.mt--23 {
  margin-top: -23px;
}
.mt-19 {
  margin-top: 19px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-29 {
  margin-top: 29px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-51 {
  margin-top: 51px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-53 {
  margin-top: 53px;
}
.mt-61 {
  margin-top: 61px;
}
.mt-104 {
  margin-top: 104px;
}
.mt-107 {
  margin-top: 107px;
}
.mt-200 {
  margin-top: 200px;
}
.mt-223 {
  margin-top: 223px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-13 {
  margin-bottom: 13px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-29 {
  margin-bottom: 29px;
}
.mb-31 {
  margin-bottom: 31px;
}
.mb-33 {
  margin-bottom: 33px;
}
.mb-37 {
  margin-bottom: 37px;
}
.mb-41 {
  margin-bottom: 41px;
}
.mb-43 {
  margin-bottom: 43px;
}
.mb-46 {
  margin-bottom: 46px;
}
.mb-53 {
  margin-bottom: 53px;
}
.mb-58 {
  margin-bottom: 58px;
}
.mr-28 {
  margin-right: 28px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-24 {
  padding-top: 24px;
}

.mobilehide {
  @include tablet {
    display: none;
  }
}

.pd-0 {
  @include tablet {
      padding: 0;
  }
}
.pb-team {
  padding: 0 150px;
  margin-top: 38px;

  @include tablet {
      padding: 0;
      margin-top: 0;
  }
}

.line-h17 {
  line-height: 1.7;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

ul, li {
  list-style-type: none;
  margin-bottom: 0;
}

body a {
  text-decoration: none;
  color: unset;
  transition: all .3s;
  cursor: pointer;
  font-family: $font-main;
  display: inline-block;
  color: $white;
  text-decoration: none;

  &:hover {
      color: $main-color;
      transition: all .3s;
  }
}

.center {
  text-align: center;
}

.color-main {
  color: $main-color;
}
.color2 {
  color: #b9b9b9 !important;
}

.border-l {
  border-left-width: 1px !important;
}
.border-r {
  border-right-width: 1px !important;
}
.border-t {
  border-top-width: 1px !important;
}
.border-b {
  border-bottom-width: 1px !important;
}

.reverse {
  @include tablet {
      flex-direction: column-reverse;
  }
}

.d-m-none {

  @include tablet {
      display: none;
  }
  
}

.divider {
  background: rgba(255, 255, 255,0.302);
  height: 1px;
  width: 100%;
  margin: 10px 0;
}